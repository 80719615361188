import * as React from 'react'
import Seo from '../components/seo'
import Layout from '../components/layout-2'
import Amplify from 'aws-amplify'
import { API } from 'aws-amplify'
import awsconfig from '../aws-exports'
import { useEffect, useState } from 'react'
import { countryListAlpha2 } from '../utils/countries'
import snake from '../images/snake.gif'

Amplify.configure(awsconfig)
API.configure(awsconfig)

const people = [
  {
    name: 'Leslie Alexander',
    email: 'leslie.alexander@example.com',
    role: 'Co-Founder / CEO',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  // More people...
]
const throttle = (func, limit) => {
  let inThrottle = false
  return function() {
    const lastFn = func
    const args = arguments
    const context = this
    if (!inThrottle) {
      func.apply(context, args)
      inThrottle = true
      setTimeout(() => inThrottle = false, limit)
    } else {

    }
  }
}
const filterBanks = (string, bankSearch, setBankSearch) => {
  const filtered = bankSearch.filter((bank) => bank.name.toUpperCase().includes(string.toUpperCase()) ||
    bank.country.toUpperCase().includes(string.toUpperCase()))
  setBankSearch(filtered)
}
const debouncedClickHandler = throttle(filterBanks, 800)
export default function Example() {
  const [banks, setBanks] = useState([])
  const [bankSearch, setBankSearch] = useState([])
  const [search, setSearch] = useState('')


  useEffect(() => {
    // fetch all the notes
    if (banks.length === 0) {
      void getBanks()
    }
  }, [banks])

  useEffect(() => {
    // fetch all the notes
    debouncedClickHandler(search, banks, setBankSearch)
  }, [search])
  const getBanks = async () => {
    const response = await API.get('getBanks2', '/api/banks')
    let map = response.map(bank => {
      return {
        ...bank,
        country: bank.countries.map(country => (countryListAlpha2[country])).join(', '),
      }
    })
    setBanks(map)
    setBankSearch(map)
  }
  if (banks.length === 0) {
    return (
      <Layout>
        <Seo title="Supported banks" />
        <div className="h-56 grid grid-cols-1 gap-4 sm:grid-cols-1 justify-items-center">

          <div className="justify-items-center">
            <img className="ml-5 mb-5 justify-center" src={snake} alt="loading" />
            Loading banks
          </div>
        </div>
      </Layout>
    )
  }
  return (
    <Layout>
      <Seo title="Supported banks" />
      <div className="h-16">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <input
          onChange={e => setSearch(e.target.value)}
          value={search}
          type="text"
          name="search"
          id="search"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Search bank"
        />
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

        {bankSearch.map((bank, index) => (
          <div
            key={index}
            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
          >
            <div className="flex-shrink-0">
              <img className="h-10 w-10 rounded-full" src={bank.logo} alt="" />
            </div>
            <div className="min-w-0 flex-1">
              <a href="#" className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900">{bank.name}</p>
                <p className="truncate text-sm text-gray-500">
                  {bank.country}
                </p>
              </a>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}